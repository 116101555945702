import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import Button from '@swe/shared/ui-kit/components/button';
import RadioGroup from '@swe/shared/ui-kit/components/form/radio-group';
import { GeolocationControl } from '@swe/shared/ui-kit/components/google-map';
import { ChevronLeftIcon } from '@swe/shared/ui-kit/components/icon';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';

import Text from '@swe/shared/ui-kit/components/text';

import React, { useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

import StoreAbout from 'common/components/store-about';
import StoreList from 'common/components/store-list';
import { DeliveryRoot } from 'common/containers/header/containers/sub-header/components/delivery-root';
import PickupRoot from 'common/containers/header/containers/sub-header/components/pickup-root';
import { getDeliveryOptions } from 'common/containers/header/containers/sub-header/constants';
import Map from 'common/containers/header/containers/sub-header/containers/map';
import SearchAddress from 'common/containers/header/containers/sub-header/containers/search-address';
import { usePickupDelivery, View } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery';
import { ShopFulfillmentType } from 'entities/shop/info';

const MobileModals = () => {
  const [isStoreListVisible, openStoreList, closeStoreList] = useToggleable();
  const [isStoreDetailsVisible, openStoreDetails, closeStoreDetails] = useToggleable();
  const [isSearchModalVisible, openSearchModal, closeSearchModal] = useToggleable();

  const {
    mapRef,
    isDragging,
    isOpened,
    close,
    orderType,
    pickup,
    delivery,
    mapProps,
    setDeliveryAddress,
    setStore,
    setOrderType,
    submit,
    availableFulfillment,
    isStorePickupAvailable,
    submittedAddress,
    showStoreList,
  } = usePickupDelivery();

  const handleStoreInfoClick = useCallback(
    (id: EntityID) => {
      setStore(id, View.StoreDetails);
      openStoreDetails();
    },
    [openStoreDetails, setStore],
  );
  const handleAddressSelected = useCallback(
    (address: string) => {
      void setDeliveryAddress(address);
      closeSearchModal();
    },
    [closeSearchModal, setDeliveryAddress],
  );
  const handleStorePick = useCallback(
    (id: EntityID | undefined) => {
      setStore(id);
      closeStoreList();
    },
    [closeStoreList, setStore],
  );
  const handleStoreSubmit = useCallback(() => {
    closeStoreDetails();
    closeStoreList();
    void submit();
  }, [closeStoreDetails, closeStoreList, submit]);
  const handlePanToMe = useCallback(() => mapRef.current?.panToMe(), [mapRef]);
  const isPickupShopFulfillmentType = useMemo(() => orderType === ShopFulfillmentType.Pickup, [orderType]);

  const subHeaderElement = useMemo(() => {
    const onViewAllStores = (view: View.DeliveryStoreList | View.PickupStoreList) => {
      openStoreList();
      showStoreList(view);
    };

    if (isPickupShopFulfillmentType) {
      return (
        <div className={styles.subHeading}>
          <PickupRoot
            currentStore={pickup.selectedStore ?? pickup.currentStore}
            storesCount={pickup.stores.length}
            hideTitle
            isStorePickupAvailable={isStorePickupAvailable}
            onStorePreviewSelected={handleStoreInfoClick}
            onViewAllStores={onViewAllStores}
          />
        </div>
      );
    }

    return (
      <div className={styles.subHeading}>
        <DeliveryRoot
          deliveryStore={delivery.deliveryStore}
          deliveryAddress={delivery.deliveryAddress}
          deliveryAddressSecondLine={delivery.deliveryAddressSecondLine}
          isDeliveryInfoHidden={isDragging}
          onOpenSearch={openSearchModal}
          onAddressSecondLineChange={delivery.setDeliveryAddressSecondLine}
          deliveryZone={delivery.deliveryZone}
          storesCount={delivery.availableStores?.length}
          onStorePreviewSelected={handleStoreInfoClick}
          onViewAllStores={onViewAllStores}
        />
      </div>
    );
  }, [
    isPickupShopFulfillmentType,
    pickup,
    delivery,
    openSearchModal,
    openStoreList,
    handleStoreInfoClick,
    isDragging,
    isStorePickupAvailable,
    showStoreList,
  ]);

  const storeListElement = useMemo(() => {
    const storeId = isPickupShopFulfillmentType ? pickup.selectedStoreId : delivery.deliveryStore?.id;

    const storeList = isPickupShopFulfillmentType ? pickup.stores : delivery.availableStores;

    return (
      <StoreList
        current={storeId}
        list={storeList}
        onInfoClick={handleStoreInfoClick}
        onPick={handleStorePick}
      />
    );
  }, [isPickupShopFulfillmentType, pickup, delivery, handleStoreInfoClick, handleStorePick]);

  return (
    <>
      <Modal
        desktop="default"
        mobile="fullscreen"
        visible={isOpened}
        ariaLabel="Pickup/Delivery"
        closeButtonColor="light"
        onClose={submittedAddress ? close : undefined}
        subHeading={subHeaderElement}
        heading={
          <RadioGroup<ShopFulfillmentType>
            className={styles.radioGroup}
            variant="section"
            name="delivery-type"
            value={orderType}
            options={getDeliveryOptions(availableFulfillment)}
            onChange={setOrderType}
          />
        }
        footer={
          <Stack>
            <div className={styles.extraControls}>
              <GeolocationControl onClick={handlePanToMe} />
            </div>
            <Stack direction="row">
              <Button
                block
                disabled={
                  orderType === ShopFulfillmentType.Delivery
                    ? !(delivery.deliveryAddress && delivery.deliveryStore)
                    : !isStorePickupAvailable
                }
                onClick={submit}
                name="shop-now"
              >
                Shop Now
              </Button>
            </Stack>
          </Stack>
        }
        background={
          <Map
            {...mapProps}
            controls={[]}
          />
        }
      />
      <Modal
        ariaLabel="Search address"
        visible={isSearchModalVisible}
        onClose={closeSearchModal}
      >
        <SearchAddress
          className={styles.searchAddress_mobile}
          onAddressSelected={handleAddressSelected}
        />
      </Modal>
      <Modal
        ariaLabel="All stores"
        mobile="fullscreen"
        visible={isStoreListVisible}
        heading="All stores"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fillHeight
        renderBackButton={(props) => (
          <Button
            {...props}
            icon={ChevronLeftIcon}
            color="light"
            onClick={closeStoreList}
            ariaLabel="Back"
            size="sm"
          />
        )}
      >
        {storeListElement}
      </Modal>
      <Modal
        ariaLabel={pickup.selectedStore?.name || ''}
        mobile="fullscreen"
        isStacked
        heading={
          pickup.selectedStore?.name && (
            <Text
              variant="headline"
              size="md"
            >
              {pickup.selectedStore.name}
            </Text>
          )
        }
        footer={
          <Button
            block
            onClick={handleStoreSubmit}
            name="shop-now"
            disabled={!isStorePickupAvailable}
          >
            Shop Now
          </Button>
        }
        visible={isStoreDetailsVisible}
        onClose={closeStoreDetails}
      >
        {pickup.selectedStore && <StoreAbout {...pickup.selectedStore} />}
      </Modal>
    </>
  );
};

export { MobileModals };
export default MobileModals;
